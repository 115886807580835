import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

const NotFound = () => {
  return <Layout title="Page Not Found (404)">
  <Head title="Page Not Found (404)" />
    <p>
        <Link to="/">
            Head home
        </Link>
    </p>
  </Layout>
}

export default NotFound
